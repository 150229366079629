@import "node_modules/bootstrap/scss/bootstrap";

//Variables
$black : #080808;
$primary: #E3704A;
$poemas-para-usar: #0178C9;
$colores-primarios: #B53636;
$ojodrilos: #36B578;
$el-amor: #B59136;
$cono: #F34F34;
$cualquiera: #7536B5;

$font-main: "Alegreya", sans-serif;
$font-title: "Passion One", serif;

//General
html{
	scroll-behavior: smooth;
}

body {
	font-family: $font-main;
    color: $black;
    overflow-x: hidden;
    font-weight: 400;
    background: #fdfdfd;
    color: $black;
}

h1,
h2,
a,
a.btn,
button,
label{
	font-family: $font-title;
}

a{
	color: $black;
}

a:hover,
h1 a, h1 a:hover{
	text-decoration: none;
	color: $black;
	transition: .4s ease;
}

h1 a:hover{
	opacity: .8;
}

p:last-of-type{
	margin-bottom: 0;
}

a.btn,
button.btn{
	background: $black;	
	padding: 0px 20px;
	height: 42px;
	line-height: 42px;
	color: #f4f4f4;
	text-align: center;
	letter-spacing: 0.1em;
	border-radius: 0;
	font-size: 1.1rem;
	transition: 0.9s cubic-bezier(0.17, 0.84, 0.44, 1);
	text-shadow: none;

	&:hover{
		color: #fff;
		letter-spacing: .125em;
		background: $primary;
	}

	&.btn--inverted{
		background: $black;
		color: #fff;

		&:hover{
			background: $black;
			color: #fff;
		}
	}
}

h1{
	font-size: 4rem;
	line-height: .84;
	letter-spacing: 0.06em;
}

h2{
	font-size: 2.5rem;
	line-height: .84;
	letter-spacing: 0.06em;	
}

img{
	max-width: 100%;
	height: auto;
}

header{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: .75rem 1.5rem;
	background: #fff;
	transition: .5s ease;
	box-shadow: 0 2px 2px rgba(0,0,0,.05);
	z-index: 200;

	.logo{
		display: block;
		width: 50px;
		height: auto;
	}

	ul{
		list-style: none;
		display: flex;
		margin: 0;
		padding: 0;
		font-size: 1.3rem;
		justify-content: end;
	}

	a{
		text-decoration: none;
		letter-spacing: 0.1em;		
		transition: 0.9s cubic-bezier(0.17, 0.84, 0.44, 1);
		line-height: 1;

		&:hover{
			letter-spacing: .125em;
		}
	}

	li+li{
		margin-left: 2rem;
	}

	.social img{
		width: 24px;
		height: 24px;
		transition: 0.9s cubic-bezier(0.17, 0.84, 0.44, 1);

		&:hover{
			transform: scale(1.1);
		}
	}
}

body.contacto header,
body.home header{
	box-shadow: none;
	background: none;
}

#cafecito-btn-fixed{
	position: fixed;
	right: 1rem;
	bottom: 1rem;
	z-index: 30;
	transition: .2s ease-in-out;

	&:hover{
		opacity: .9;
		transform: scale(1.02);
	}
}

.intro-site{
	font-size: 1.3rem;
	position: relative;

	em{
		font-size: 1.5em;
		font-weight: 700;
	}

	.col-content{
		padding-bottom: 3rem;
		padding-top: 3rem;
	}

/* 	&:before{
		background: #eee;
		content: "";
		position: absolute;
		left: 1.5rem;
		top: 0;
		bottom: 1.5rem;
		right: 1.5rem;
		z-index: -1;
	}  */

/* 	.btn{
		display: block;
		max-width: 15rem;
		margin: 2rem auto 0;
		background: #fff;
		color: $black;
		font-size: 1.2rem;

		&:hover{
			color: $black;
			background: rgba(255,255,255,.8);
		}
	} */

	.go-to-books{
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 1.5rem;
		width: 16px;
		transition: 0.9s cubic-bezier(0.17, 0.84, 0.44, 1);

		&:hover{
			transform: translateY(4px);
		}
	}

}

.intro{
	vertical-align: middle;
	font-style: italic;
	font-weight: 500;
	font-size: 1.25rem;	
	margin-bottom: 1rem;
}

.presenta{
	width: 150px;
	display: inline-block;
	margin-right: .5rem;
    margin-bottom: 0.2rem;
}

.col-lg-6{
	padding: 2rem 15px;
}

body.contacto .landing-intro,
body.home .landing-intro{
	margin-top: 0;
}

.landing-intro{
	overflow-x: hidden;
	margin-top: 74px;
}

.landing-intro,
.landing-intro>.container,
.landing-intro>.container>.row{
	min-height: 100vh;
}

.book-meta,
.section-intro{
	font-size: 1.25rem;
	font-weight: 500;
	margin-top: 1rem;

	.name{
		font-style: italic;
		font-weight: 800;
	}
}

.book-desc{
	padding-left: 1.5rem;
	margin-top: 1.5rem;
	border-left: 6px solid $primary;
	font-weight: 500;
	line-height: 1.5;
	font-size: 1.1rem;

	a{
		font-weight: 700;
		color: $black;
		font-family: $font-main;
	}
}

.book-download{
	margin-top: 2rem;

	p{		
		font-size: .875rem;
	}

	a.btn{
		margin-bottom: .5rem;
		width: 100%;
	}
}

.book-cover{
	position: relative;

	img{
		position: relative;
		z-index: 20;
	}

	&:before{
		position: absolute;
		left: 50%;
		right: -1000px;
		top: 0;
		bottom: 0;
		content: "";
		background: $primary;
		box-shadow: -1px 0 0px 0px rgba(8, 8, 8, 0.25);
	}
}

.col-content{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

body.modo-luciernaga,
.body.modo-luciernaga{

	.intro-mobile{
		background: $primary;
	}
}

body.poemas-para-usar,
.body.poemas-para-usar{
	.book-cover:before,
	a.btn:hover,
	.intro-mobile{
		background-color: $poemas-para-usar;
	}

	.book-desc{
		border-color: $poemas-para-usar;
	}
}

body.colores-primarios,
.body.colores-primarios{
	.book-cover:before,
	a.btn:hover,
	.intro-mobile{
		background-color: $colores-primarios;
	}

	.book-desc{
		border-color: $colores-primarios;
	}
}

body.ojodrilos,
.body.ojodrilos{
	.book-cover:before,
	a.btn:hover,
	.intro-mobile{
		background-color: $ojodrilos;
	}

	.book-desc{
		border-color: $ojodrilos;
	}
}

body.el-amor,
.body.el-amor{
	.book-cover:before,
	a.btn:hover,
	.intro-mobile{
		background-color: $el-amor;
	}

	.book-desc{
		border-color: $el-amor;
	}
}

body.cono,
.body.cono{
	.book-cover:before,
	a.btn:hover,
	.intro-mobile{
		background-color: $cono;
	}

	.book-desc{
		border-color: $cono;
	}
}

body.cualquiera,
.body.cualquiera{
	.book-cover:before,
	a.btn:hover,
	.intro-mobile{
		background-color: $cualquiera;
	}

	.book-desc{
		border-color: $cualquiera;
	}
}

form{
	margin: 2rem 0 0;
	max-width: 600px;

	label{
		font-size: 1.5rem;
		margin-bottom: .25rem;
		letter-spacing: 0.1em;
		display: none;
	}

	.form-control{
		border-radius: 0;
		height: 48px;
		transition: all .4s ease;
	}

	.form-control:focus {
	    border-color: rgba(0,0,0,.25);
	    outline: 0;
	    box-shadow: none;

	}

	textarea.form-control{
		height: 7rem;
	}

	.form-group+.form-group{
		margin-top: 1.5rem;
	}
}

.page-section{
	padding: 3rem 0;

	&__intro{
		padding-top: 8rem;
	}
}

@media screen and (max-width: 768px){
	.book-cover img{
		margin: 2rem auto;
	}

	body{
		padding-bottom: 4rem;
	}

	.book-cover:before{
		display: none;
	}

	.intro-mobile{
		color: #f4f4f4;
		padding: 2rem;
		margin-bottom: 3rem;
		text-shadow: 1px 1px 1px rgba($black, .2);

		h1 a{
			color: #f4f4f4;
		}

	    a.btn{
	    	margin-top: 1.5rem;
	    }
	}

	.book-cover-mobile{
		height: auto;
	    height: auto;
	    right: -32%;
	    position: relative;
	}

	.landing-intro, .landing-intro > .container, .landing-intro > .container > .row{
		min-height: initial;
	}

	body.poemas-para-usar,
	.body.poemas-para-usar{
		.intro-mobile{
			background: $poemas-para-usar;
		}
	}

	body.colores-primarios{
		.intro-mobile{
			background: $colores-primarios;
		}
	}

	body.ojodrilos{
		.intro-mobile{
			background: $ojodrilos;
		}
	}

	body.el-amor{
		.intro-mobile{
			background: $el-amor;
		}
	}

	body.cono{
		.intro-mobile{
			background: $cono;
		}
	}


	body.cualquiera{
		.intro-mobile{
			background: $cualquiera;
		}
	}
}

@media screen and (max-width: 500px){
	.book-cover-mobile{
		right: 0;
		width: 250px;
		margin: 0 auto;
		display: block;
	}

	.intro-mobile{
		padding-bottom: 3rem;
		text-align: center;
		margin-bottom: 2rem;

		a.btn{			
			min-width: 10rem;
		}
	}

	#book-download{
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.book-download div+div{
		margin-top: 2rem;
	}

	.book-download a.btn{
		margin-bottom: .5rem;
	}

	.col-lg-6{
		padding: 0 15px;
	}
}

@media screen and (max-width: 420px){
	h1{
		font-size: 4rem;
	}
}